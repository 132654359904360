import React from 'react'
import { Box, AspectRatio, Image, Text, Flex } from '@chakra-ui/react'
import CheckExMark from '../../../atoms/CheckExMark'

const ThemeItem = ({ item, isSelected, onClick, ...rest }) => {
	const { id, thumb, poster, label, category } = item
	return (
		<Box
			bg="white"
			boxShadow="base"
			borderRadius="lg"
			position="relative"
			onClick={onClick}
			w={rest.w || 'auto'}
			{...rest}>
			<Box borderTopRadius="lg" overflow="hidden" position="relative">
				<AspectRatio ratio={4 / 3}>
					<Image src={thumb} alt="Cover Image" h="100%" _hover={{ cursor: 'pointer' }} />
				</AspectRatio>
			</Box>
			<Flex w="full" bg="white" p="1rem" borderBottomRadius="lg" justify="space-between" align="center">
				<Flex direction="column">
					<Text
						_hover={{ cursor: 'pointer' }}
						fontSize="lg"
						fontWeight="bold"
						lineHeight="24px"
						textTransform="capitalized"
						isTruncated>
						{label}
					</Text>
					<Text _hover={{ cursor: 'pointer' }} fontSize="xs" color="#6C7A88" isTruncated>
						{category}
					</Text>
				</Flex>
				<Flex>{isSelected && <CheckExMark render={true} check={true} scale={0.25} color={'#04D898'} />}</Flex>
			</Flex>
		</Box>
	)
}

export default ThemeItem
